/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useReducer } from 'react';
import { useAuth } from 'src/sessions/Session';

const initialState = {
  isAuthenticated: false,
  handleSignIn: () => {},
  handleSignOut: () => {}
};

const CreateRootContext = React.createContext({
  ...initialState
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'signIn':
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        userLogin: action.payload.userLogin
      };
    case 'signOut':
      return {
        ...state,
        isAuthenticated: false
      };
    default:
      return state;
  }
};

const RootContext = ({ children }) => {
  const { isAuthenticated, signIn, signOut } = useAuth();
  const [stateAuth, dispatchAuth] = useReducer(reducer, {
    ...initialState,
    isAuthenticated
  });

  const handleSignIn = async function (payload) {
    console.log(payload);
    //authentication(project, session);
    const { username, password } = payload;
    const result = await signIn(username, password);
    console.log(result);
    dispatchAuth({
      type: 'signIn',
      payload: { isAuthenticated: true }
    });
  };

  const handleSignOut = async function () {
    await signOut();
    dispatchAuth({
      type: 'signOut'
    });
  };

  return (
    <Fragment>
      <CreateRootContext.Provider
        value={{
          ...stateAuth,
          handleSignIn,
          handleSignOut
        }}
      >
        {children}
      </CreateRootContext.Provider>
    </Fragment>
  );
};

export default RootContext;
export const useRootContext = () => useContext(CreateRootContext);
