import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'src/routers/Routes';
import { createBrowserHistory } from 'history';
import RootContext from './contexts/RootContext';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ProvideAuth, VerifyToken } from './sessions/Session';
import { ProvideAlert } from 'src/components/Alert';
import { GlobalLoadingBar, ProvideLoading } from 'src/apis';
import 'react-dates/lib/css/_datepicker.css';
import 'reactjs-popup/dist/index.css';
import ReactTooltip from 'react-tooltip';

function App() {
  const history = createBrowserHistory();
  return (
    <ProvideLoading>
      <GlobalLoadingBar />
      <ProvideAuth>
        <ProvideAlert>
          <VerifyToken />
          <RootContext>
            <Router history={history}>
              <Routes />
            </Router>
          </RootContext>
        </ProvideAlert>
      </ProvideAuth>
    </ProvideLoading>
  );
}

export default App;
