import React, { useState, useEffect } from 'react';
import { useGetConfigList, useGetEngagementJobList } from 'src/apis';
import { Table } from 'src/components';
import Filter from './Filter';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const CommentCrawlback = () => {
  // const [configList] = useGetConfigList();
  // console.log(configList);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Original ID',
        accessor: 'job.originalId',
        width: 10
      },
      {
        Header: 'Link',
        accessor: 'job.link',
        width: 100
      },
      {
        Header: 'Stream Type',
        accessor: 'objectModel.streamType',
        width: 50
      },
      {
        Header: 'Social Media',
        accessor: 'objectModel.socialMedia',
        width: 50
      },
      {
        Header: 'Object Name',
        accessor: 'objectModel.objectName',
        width: 100
      },
      {
        Header: 'Status',
        accessor: 'job.status',
        width: 50
      },
      {
        Header: 'Result',
        accessor: 'job.result',
        width: 50
      },
      {
        Header: 'Error',
        accessor: 'job.error',
        accessor: (row) => {
          return (
            row.job.error && (
              <>
                <span data-tip data-for={row.job.uuid}>
                  {' '}
                  {row.job.error?.slice(0, 10)}...{' '}
                </span>
                <ReactTooltip id={row.job.uuid} type="error">
                  <span>{row.job.error}</span>
                </ReactTooltip>
              </>
            )
          );
        },
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        width: 20
      },
      {
        Header: 'Created By',
        accessor: 'user.email',
        width: 100
      },
      {
        Header: 'Toolbar',
        accessor: 'toolbar',
        width: 50
      }
    ],
    // [configList?.data]
    []
  );
  const now = moment().add(1, 'days').startOf('day');
  const [updatedFilter, filter] = Filter({
    start: now.clone().subtract(7, 'days'),
    end: now
  });
  const [externalRefresh, setExternalRefresh] = useState(null);
  const [pagination, setPagination] = useState({ offset: 0, size: 10 });
  const [result, invokeGetEngagementList] = useGetEngagementJobList();
  useEffect(() => {
    async function load() {
      if (!pagination) return;
      const result = await invokeGetEngagementList(
        pagination.offset,
        pagination.size,
        updatedFilter.start.format('YYYY-MM-DD'),
        updatedFilter.end.format('YYYY-MM-DD'),
        updatedFilter.status
      );
      //   console.log(result);
    }
    load();
  }, [
    JSON.stringify(pagination),
    JSON.stringify(updatedFilter),
    externalRefresh
  ]);
  const [table, checked] = Table({
    showCheckbox: false,
    data: result.data ? result.data.result : [],
    totalSize: result?.data?.total || 0,
    pageSize: pagination.size,
    pageIndex: pagination.offset / pagination.size,
    columns,
    fetchData: (state) => {
      console.log(state);
      setPagination({
        offset: state.pageIndex * state.pageSize,
        size: state.pageSize
      });
    }
  });
  return (
    <div className="p-6 bg-white">
      <div className="grid grid-cols-4">
        <div className="col-span-2">{filter}</div>
        <div className="col-span-2">
        </div>
      </div>
      <div className={`col-span-8 p-1 `}>
          {table}
        </div>
      {/* <Button onClick={() => invokeGetWorkerList()}>Reload</Button> */}
    </div>
  );
};

export default CommentCrawlback;
