import { useEffect, useState, createContext, useContext, useRef } from 'react';
import { ApiBaseUrl, PATHS } from './config';
import useAxios, { configure, makeUseAxios } from 'axios-hooks';
import LoadingBar from 'react-top-loading-bar';
import queryString from 'query-string';
import { useAuth, useLocalStorage } from '../sessions/Session';
import Axios from 'axios';

const {
  GET_LEADER_LIST,
  GET_WORKER_LIST,
  GET_CONFIG_LIST,
  GET_CLUSTER_STATUS,
  GET_PROJECT_LIST,
  GET_OBJECT_LIST,
  UPDATE_PROJECT_TO_CRAWLER,
  GET_CRAWLBACK_LIST,
  GET_CRAWLBACK_JOB_DETAILS,
  GET_CRAWLBACK_STATUS,
  USER_LOGIN,
  USER_ME,
  USER_LOGOUT,
  CREATE_GROUP_CRAWLBACK_JOB,
  UPDATE_WORKER_QUEUE,
  CANCEL_CRAWLBACK_JOB_VIA_CRAWLER,
  CANCEL_CRAWLBACK_JOB_VIA_DASHBOARD,
  RETRY_CRAWLBACK_JOB_VIA_DASHBOARD,
  MONITORING_GET_POST_MADE_WITH_REPLY,
  GET_ENGAGEMENT_JOB_LIST,
  CREATE_ENGAGEMENT_JOB,
  CANCEL_ENGAGEMENT_JOB,
  RETRY_ENGAGEMENT_JOB
} = PATHS;

const loadingContext = createContext();

export function ProvideLoading({ children }) {
  const loadingCtx = useProvideLoading();
  return (
    <loadingContext.Provider value={loadingCtx}>
      {children}
    </loadingContext.Provider>
  );
}

export function GlobalLoadingBar() {
  const { progress, setProgress } = useLoading();
  return (
    <LoadingBar
      color="aquamarine"
      progress={progress}
      onLoaderFinished={() => setProgress(0)}
    />
  );
}

function useProvideLoading() {
  const [progress, setProgress] = useState(0);
  const axiosRef = useRef(null);
  const progressTracker = (event) => {
    const { total, loaded } = event;
    const currentProgress = 50 + ((loaded / total) * 100) / 2;
    setProgress(currentProgress);
  };
  if (!axiosRef.current) {
    const axios = Axios.create({
      onDownloadProgress: progressTracker
    });
    axios.interceptors.request.use((config) => {
      setProgress(50);
      return config;
    });
    const useCustomAxios = makeUseAxios({ axios });
    axiosRef.current = useCustomAxios;
  }

  return {
    progress,
    setProgress,
    useCustomAxios: axiosRef.current
  };
}

export const useLoading = () => {
  return useContext(loadingContext);
};

function useGet(
  path,
  fnParameter = (args) => ({}),
  options = { isManual: false }
) {
  const { useCustomAxios } = useLoading();

  const [result, execute] = useCustomAxios(
    {
      url: path,
      method: 'get',
      withCredentials: true,
      params: options.isManual ? null : fnParameter()
    },
    {
      manual: options.isManual
    }
  );
  const invoke = async (...args) => {
    try {
      console.log('invoke', args);
      const response = await execute({
        params: fnParameter.apply(null, args)
      });
      return response;
    } catch (e) {
      console.log('error on login', e);
      return e;
    }
  };
  return [result, invoke];
}

function usePost(
  path,
  fnParameter = (args) => ({}),
  options = { isManual: false }
) {
  const { useCustomAxios } = useLoading();
  const config = {
    url: path,
    method: 'post',
    withCredentials: true,
    ...(options.isManual ? null : fnParameter())
  };
  const [result, execute] = useCustomAxios(config, {
    manual: options.isManual
  });
  const invoke = async (...args) => {
    try {
      const response = await execute(fnParameter.apply(null, args));
      return response;
    } catch (e) {
      console.log('error on login', e);
      return e;
    }
  };
  return [result, invoke];
}

export function useLogin() {
  const [result, execute] = useAxios(
    {
      url: USER_LOGIN,
      method: 'post',
      withCredentials: true
    },
    {
      manual: true
    }
  );
  const invoke = async (email, password) => {
    try {
      const response = await execute({
        data: queryString.stringify({
          email,
          password
        })
      });
      console.log('Result', response);
      return response;
    } catch (e) {
      console.log('error on login', e);
      return e;
    }
  };
  return [result, invoke];
}

export function useLogout() {
  const [result, execute] = useAxios(
    {
      url: USER_LOGOUT,
      method: 'post',
      withCredentials: true
    },
    {
      manual: true
    }
  );
  const invoke = async () => {
    try {
      const response = await execute();
      console.log('Result', response);
      return response;
    } catch (e) {
      console.log('error on login', e);
      return e;
    }
  };
  return [result, invoke];
}

export function useUserMe() {
  const { token, invalidateToken } = useAuth();
  const [result, execute] = useAxios(
    {
      url: USER_ME,
      method: 'get',
      withCredentials: true,
      headers: {
        'x-jwt-token': token
      }
    },
    {
      manual: true
    }
  );
  const invoke = async () => {
    try {
      const response = await execute();
      console.log('Result', response);
      return response;
    } catch (e) {
      console.log('error on login', e);
      if (e.response.status == 401) invalidateToken();
      return e;
    }
  };
  return [result, invoke];
}

export function useGetLeaderList(isManual) {
  return useGet(GET_LEADER_LIST, (offset, size) => ({ offset, size }), {
    isManual
  });
}

export function useGetWorkerList(isManual) {
  return useGet(GET_WORKER_LIST, () => ({}), { isManual });
}

export function useGetConfigList(isManual) {
  return useGet(GET_CONFIG_LIST, () => ({}), { isManual });
}

export function useGetClusterStatus(isManual) {
  return useGet(GET_CLUSTER_STATUS, () => ({}), { isManual });
}

export function useGetProjectList(isManual) {
  return useGet(GET_PROJECT_LIST, (isActive) => ({ is_active: isActive }), {
    isManual
  });
}

export function useGetObjectList() {
  return useGet(GET_OBJECT_LIST, (projectId) => ({ project_id: projectId }), {
    isManual: true
  });
}

export function useBulkUpdateQueueName() {
  return usePost(
    UPDATE_PROJECT_TO_CRAWLER,
    (projectId, queueName) => ({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: queryString.stringify({ queue: queueName, project_id: projectId })
    }),
    { isManual: true }
  );
}

export function useGetCrawlbackJobList() {
  return useGet(
    GET_CRAWLBACK_LIST,
    (offset, size, start, end, status) => {
      return {
        offset,
        size,
        start,
        end,
        status
      };
    },
    { isManual: true }
  );
}

export function useGetCrawlbackStatus() {
  return useGet(
    GET_CRAWLBACK_STATUS,
    (from, to) => ({
      from,
      to
    }),
    { isManual: true }
  );
}

export function useGetCrawlbackJobDetails(groupId) {
  return useGet(
    GET_CRAWLBACK_JOB_DETAILS.replace('{id}', groupId),
    () => ({}),
    { isManual: true }
  );
}

export function useCreateNewGroupCrawlbackJob() {
  return usePost(
    CREATE_GROUP_CRAWLBACK_JOB,
    (project_id, user_id, object_ids, start, end, queue_name) => {
      const data = {
        project_id,
        user_id,
        object_ids: object_ids.join(','),
        start,
        end,
        queue_name
      };
      return {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: queryString.stringify(data)
      };
    },
    { isManual: true }
  );
}

export function useReassignWorkerToQueue() {
  return usePost(
    UPDATE_WORKER_QUEUE,
    (worker_id, queue_id) => {
      const data = {
        worker_id,
        queue_id
      };
      return {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: queryString.stringify(data)
      };
    },
    { isManual: true }
  );
}

export function useCancelCrawlbackJob() {
  return usePost(
    CANCEL_CRAWLBACK_JOB_VIA_CRAWLER,
    (id) => {
      const data = {
        id
      };
      return {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: queryString.stringify(data)
      };
    },
    { isManual: true }
  );
}

export function useCancelCrawlbackJobFromDashboard() {
  return usePost(
    CANCEL_CRAWLBACK_JOB_VIA_DASHBOARD,
    (id) => {
      const data = {
        id
      };
      return {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: queryString.stringify(data)
      };
    },
    { isManual: true }
  );
}

export function useRetryCrawlbackJobFromDashboard() {
  return usePost(
    RETRY_CRAWLBACK_JOB_VIA_DASHBOARD,
    (id) => {
      const data = {
        ids: [id].join(',')
      };
      return {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: queryString.stringify(data)
      };
    },
    { isManual: true }
  );
}

export function useMonitoringGetPostMadeWithReplyCount() {
  const { token, invalidateToken } = useAuth();
  const [result, execute] = useAxios(
    {
      url: MONITORING_GET_POST_MADE_WITH_REPLY,
      method: 'post',
      withCredentials: true,
      headers: {
        'x-jwt-token': token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },
    {
      manual: true
    }
  );
  const invoke = async (projectId, start, end, objectList) => {
    try {
      const response = await execute({
        data: queryString.stringify({
          project_id: projectId,
          start,
          end,
          objectIds: objectList.join(',')
        })
      });
      console.log('Result', response);
      return response;
    } catch (e) {
      console.log('error on login', e);
      if (e.response.status == 401) invalidateToken();
      return e;
    }
  };
  return [result, invoke];
}

export function useCancelEngagementJobFromDashboard() {
  return usePost(
    CANCEL_ENGAGEMENT_JOB,
    (id) => {
      const data = {
        id
      };
      return {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: queryString.stringify(data)
      };
    },
    { isManual: true }
  );
}

export function useRetryEngagementJobFromDashboard() {
  return usePost(
    RETRY_ENGAGEMENT_JOB,
    (id) => {
      const data = {
        ids: id
      };
      return {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: queryString.stringify(data)
      };
    },
    { isManual: true }
  );
}

export function useGetEngagementJobList() {
  return useGet(
    GET_ENGAGEMENT_JOB_LIST,
    (offset, size, start, end, status) => {
      return {
        offset,
        size,
        start,
        end,
        status
      };
    },
    { isManual: true }
  );
}


export function useCreateNewEngagementJob() {
  return usePost(
    CREATE_ENGAGEMENT_JOB,
    (project_id, user_id, object_id, original_id, link) => {
      const data = {
        project_id,
        user_id,
        object_id,
        original_id,
        link
      };
      return {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: queryString.stringify(data)
      };
    },
    { isManual: true }
  );
}
