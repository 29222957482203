import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import RouteWithLayout from 'src/components/RouteWithLayout/RouteWithLayout';
import { LayoutMain, LayoutMinimal } from 'src/layouts';
import {
  LEADER_LIST,
  WORKER_LIST,
  CLUSTER_STATUS,
  PROJECT_LIST,
  CRAWLBACK_PANEL,
  LOGIN,
  QUEUE_LIST,
  COMMENT_CRAWLBACK
} from './paths';
import {
  LeaderList,
  WorkerList,
  ClusterStatus,
  ProjectList,
  CrawlbackPanel,
  LoginPage,
  QueueList,
  CommentCrawlback
} from 'src/pages';
const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={CRAWLBACK_PANEL} />
      <RouteWithLayout
        component={LoginPage}
        exact
        layout={LayoutMinimal}
        path={LOGIN}
        requiresAuthorization={false}
      />
      {/* <RouteWithLayout
        component={ClusterStatus}
        exact
        layout={LayoutMain}
        path={CLUSTER_STATUS}
        requiresAuthorization={true}
        roles={['admin', 'waskita']}
      /> */}
      <RouteWithLayout
        component={LeaderList}
        exact
        layout={LayoutMain}
        path={LEADER_LIST}
        requiresAuthorization={true}
        roles={['admin', 'waskita']}
      />
      <RouteWithLayout
        component={WorkerList}
        exact
        layout={LayoutMain}
        path={WORKER_LIST}
        requiresAuthorization={true}
        roles={['admin', 'waskita']}
      />
      <RouteWithLayout
        component={ProjectList}
        exact
        layout={LayoutMain}
        path={PROJECT_LIST}
        requiresAuthorization={true}
        roles={['admin', 'waskita']}
      />
      <RouteWithLayout
        component={CrawlbackPanel}
        exact
        layout={LayoutMain}
        path={CRAWLBACK_PANEL}
        requiresAuthorization={true}
        roles={['admin', 'waskita']}
      />
      <RouteWithLayout
        component={QueueList}
        exact
        layout={LayoutMain}
        path={QUEUE_LIST}
        requiresAuthorization={true}
        roles={['admin', 'waskita']}
      />
      <RouteWithLayout
        component={CommentCrawlback}
        exact
        layout={LayoutMain}
        path={COMMENT_CRAWLBACK}
        requiresAuthorization={true}
        roles={['admin', 'waskita']}
      />
    </Switch>
  );
};

export default Routes;
