import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import DateRangePicker from 'src/components/DateRangePicker';
import { Button } from 'src/components';

const isFilterChange = (current, now) => {
  return (
    now.status != current.status ||
    current.start.valueOf() != now.start.valueOf() ||
    now.end.valueOf() != current.end.valueOf()
  );
};

const Filter = ({ start, end }) => {
  const [status, setStatus] = useState('running');
  const [timeFrame, setTimeFrame] = useState({
    start: moment(start),
    end: moment(end)
  });
  const [updatedFilter, updateFilter] = useState({
    start,
    end,
    status
  });
  return [
    updatedFilter,
    <div>
      <div>
        <DateRangePicker
          onChange={(update) => {
            setTimeFrame({
              start: update.start,
              end: update.end
            });
          }}
          start={timeFrame.start}
          end={timeFrame.end}
        />
      </div>
      <div>
        <select value={status} onChange={(e) => setStatus(e.target.value)}>
          <option value="pending">Pending</option>
          <option value="running">Running</option>
          <option value="error">Error</option>
        </select>
      </div>
      <div>
        <Button
          onClick={() =>
            updateFilter({
              start: timeFrame.start,
              end: timeFrame.end,
              status,
              refresh: new Date()
            })
          }
        >
          {isFilterChange(updatedFilter, { status, ...timeFrame })
            ? 'Update'
            : 'Refresh'}
        </Button>
      </div>
    </div>
  ];
};

export default Filter;
