import React, { Fragment } from 'react';
import Body from './components/Body/Body';

const Minimal = (props) => {
  const { children } = props;
  return (
    <Fragment>
      <Body>{children}</Body>
    </Fragment>
  );
};

export default Minimal;
