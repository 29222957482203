import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import { useGetLeaderList } from 'src/apis';
import { Table, Button } from 'src/components';

const getLeaderName = (leader) => {
  return `${leader.target.stream_type} ${leader.target.specific_resource_type} ${leader.target.target_name} ${leader.target.extra_content}`;
};

const StatusLight = (props) => {
  if (!props.historicalStatus.length) return <div></div>;
  const lightList = props.historicalStatus.map(({ status, id }) => {
    let color = 'grey';
    if (status == 'success') color = 'green';
    else if (status == 'error') color = 'red';
    else color = 'grey';
    return (
      <div
        key={id}
        style={{ backgroundColor: color, float: 'left', height: 5, width: 5 }}
      ></div>
    );
  });
  const total = props.historicalStatus.length;
  const successCount = props.historicalStatus.reduce((memo, { status }) => {
    return memo + (status == 'success' ? 1 : 0);
  }, 0);
  console.log('Total / Success', total, successCount);
  const percentage = Math.floor((successCount * 100) / total);
  return (
    <div>
      <div>{percentage}%</div>
      {lightList}
    </div>
  );
};

const LeaderList = () => {
  const [selectedLeader, setSelectedLeader] = useState(null);
  const historyColumns = useMemo(
    () => [
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        width: 100
      },
      {
        id: 'count',
        Header: 'Count',
        accessor: (row) => row?.count || 0,
        width: 100
      },
      {
        id: 'duration',
        Header: 'Duration (minutes)',
        accessor: (row) => {
          const createdAt = moment(row.createdAt);
          const updatedAt = moment(row.updatedAt);
          return updatedAt.diff(createdAt, 'minutes');
        },
        width: 100
      },
      {
        id: 'createdAt',
        Header: 'Created At',
        accessor: (row) => moment(row.createdAt).format('YYYY-MM-DD HH:mm'),
        width: 100
      },

      {
        id: 'start',
        Header: 'Start',
        accessor: (row) =>
          moment(row.timeFrame.start).format('YYYY-MM-DD HH:mm'),
        width: 100
      },
      {
        id: 'end',
        Header: 'End',
        accessor: (row) => moment(row.timeFrame.end).format('YYYY-MM-DD HH:mm'),
        width: 100
      },
      {
        id: 'updatedAt',
        Header: 'Updated At',
        accessor: (row) => moment(row.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
        width: 100
      }
    ],
    []
  );
  const columns = React.useMemo(
    () =>
      [
        {
          id: 'social_media',
          Header: 'Social Media',
          accessor: 'existingLeader.leader.target.specific_resource_type',
          width: 100
        },
        {
          id: 'stream_type',
          Header: 'Stream Type',
          accessor: 'existingLeader.leader.target.stream_type',
          width: 100
        },
        {
          id: 'content',
          Header: 'Content',
          accessor: 'existingLeader.leader.target.target_name',
          width: 100
        },
        {
          id: 'extra_content',
          Header: 'Extra Content',
          accessor: 'existingLeader.leader.target.extra_content',
          width: 100
        },

        {
          id: 'queue_name',
          Header: 'Queue Name',
          accessor: 'existingLeader.config.queueName',
          width: 100
        },
        {
          id: 'status_light',
          Header: 'Status',
          accessor: (row) => StatusLight(row.statistic),
          width: 100
        },
        {
          id: 'last_deployment',
          Header: 'Last Deploy',
          accessor: (row) => {
            if (row.existingLeader?.implementedBy?.createdAt) {
              const totalStream =
                row.statistic?.lastDeploymentStatus?.count || 0;
              const status =
                row.existingLeader?.implementedBy.status || 'not deployed';
              return (
                <div className="grid grid-cols-4">
                  <div className="col-span-2 p-1">
                    {moment(row.existingLeader.implementedBy.createdAt).format(
                      'YYYY-MM-DD HH:mm'
                    )}
                  </div>
                  <div className="col-span-1 p-1">{totalStream}</div>
                  <div className="col-span-1 p-1">{status}</div>
                </div>
              );
            } else return <div></div>;
          },
          width: 100
        },
        {
          id: 'next_deployment',
          Header: 'Next Deploy',
          accessor: (row) => {
            return moment(row.nextDeployment).format('YYYY-MM-DD HH:mm');
          },
          width: 20
        },
        {
          id: 'toolbar',
          Header: 'Toolbar',
          accessor: (row) => {
            if (
              selectedLeader &&
              row.existingLeader.leader.id ==
                selectedLeader.existingLeader.leader.id
            )
              return (
                <Button onClick={() => setSelectedLeader(null)}>Close</Button>
              );
            return (
              <Button onClick={() => setSelectedLeader(row)}>
                Show History
              </Button>
            );
          },
          width: 100
        }
      ].filter((item) => {
        if (!selectedLeader) return true;
        else {
          return ['social_media', 'stream_type', 'content', 'toolbar'].filter(
            (name) => name == item.id
          ).length;
        }
      }),
    [selectedLeader]
  );
  const [result, invokeGetLeaderList] = useGetLeaderList(true);
  const [pagination, setPagination] = useState({ offset: 0, size: 10 });
  useEffect(() => {
    async function load() {
      if (!pagination) return;
      const result = await invokeGetLeaderList(
        pagination.offset,
        pagination.size
      );
      //   console.log(result);
    }
    load();
  }, [JSON.stringify(pagination)]);
  const [table, checked] = Table({
    showCheckbox: false,
    data: result.data ? result.data.result : [],
    totalSize: result?.data?.total || 0,
    pageSize: pagination.size,
    pageIndex: pagination.offset / pagination.size,
    columns,
    onSearch: (row, phrase) => {
      const status = row.existingLeader?.implementedBy.status || 'not deployed';
      const targetName = row.existingLeader.leader.target.target_name;
      const socialMedia =
        row.existingLeader.leader.target.specific_resource_type;
      const extraContent = row.existingLeader.leader.target.extra_content || '';
      const streamType = row.existingLeader.leader.target.stream_type;
      const queue = row.existingLeader?.config.queueName || 'no queue';
      return (
        `${status} ${targetName} ${socialMedia} ${extraContent} ${streamType} ${queue}`
          .toLowerCase()
          .indexOf(phrase) >= 0
      );
    },
    fetchData: (state) => {
      console.log(state);
      setPagination({
        offset: state.pageIndex * state.pageSize,
        size: state.pageSize
      });
    }
  });
  const realHistoricalStatus = selectedLeader
    ? selectedLeader.statistic.historicalStatus
    : [];
  const [historyTable] = Table({
    showCheckbox: false,
    data: realHistoricalStatus,
    totalSize: realHistoricalStatus.length,
    columns: historyColumns
  });
  return (
    <div className="p-6 bg-white">
      <div className="grid grid-cols-8">
        <div className={`col-span-${selectedLeader ? '3' : '8'} p-1 `}>
          {table}
        </div>
        {selectedLeader && (
          <div className="col-span-5 p-1">
            <div className="p-6">
              <div className="grid grid-cols-4">
                <div className="col-span-2 p-1">
                  {getLeaderName(selectedLeader.existingLeader.leader)}
                </div>
                <div className="col-span-1 p-1">
                  <StatusLight {...selectedLeader.statistic} />
                </div>
                <div className="col-span-1 p-1">
                  <Button onClick={() => setSelectedLeader(null)}>Close</Button>
                </div>
              </div>
            </div>
            {historyTable}
          </div>
        )}
      </div>
      <Button
        onClick={() => invokeGetLeaderList(pagination.offset, pagination.size)}
      >
        Reload
      </Button>
    </div>
  );
};

export default LeaderList;
