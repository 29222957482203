import React from 'react';

const ButtonText = (props) => {
  return (
    <button
      {...props}
      //className="background-transparent mb-1 mr-1 px-3 py-1 text-purple-500 text-xs font-bold outline-none focus:outline-none uppercase"
      type="button"
      style={{ transition: 'all .15s ease' }}
    >
      {props.children}
    </button>
  );
};

export default ButtonText;
