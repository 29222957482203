import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef
} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const alertContext = createContext();
export function ProvideAlert({ children }) {
  const alert = useProvideAlert();
  return (
    <alertContext.Provider value={alert}>
      <Popup
        open={!!alert.alert.type}
        closeOnDocumentClick
        onClose={alert.closeModal}
      >
        {alert.alert.text}
      </Popup>
      {children}
    </alertContext.Provider>
  );
}

export const useAlert = () => {
  return useContext(alertContext);
};

function useProvideAlert() {
  const alertTimeer = useRef(null);
  const defaultState = {
    type: '',
    text: ''
  };
  const [alert, setAlert] = useState(defaultState);
  const setAlertWithTimeout = (type, text, timeout) => {
    if (alertTimeer.current) {
      clearTimeout(alertTimeer.current);
      alertTimeer.current = null;
    }
    if (timeout) {
      alertTimeer.current = setTimeout(() => closeModal(), timeout);
    }
    setAlert({ type: type, text });
  };
  const showInfo = (text, timeout) =>
    setAlertWithTimeout('info', text, timeout);
  const showWarning = (text, timeout) =>
    setAlertWithTimeout('warning', text, timeout);
  const showError = (text, timeout) =>
    setAlertWithTimeout('error', text, timeout);
  const closeModal = () => {
    if (alertTimeer.current) {
      clearTimeout(alertTimeer.current);
      alertTimeer.current = null;
    }
    setAlert(defaultState);
  };

  return {
    alert,
    showInfo,
    showError,
    showWarning,
    closeModal
  };
}
