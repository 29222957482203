import React, { Fragment } from 'react';
import { MainBody, MainHeader } from './components';

const Main = (props) => {
  const { children } = props;
  return (
    <Fragment>
      <MainHeader />
      <MainBody>{children}</MainBody>
    </Fragment>
  );
};

export default Main;
