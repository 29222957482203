import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import DateRangePicker from 'src/components/DateRangePicker';
import { Button, Table } from 'src/components';
import {
  useGetProjectList,
  useGetObjectList,
  useGetConfigList,
  useCreateNewGroupCrawlbackJob,
  useGetCrawlbackStatus
} from 'src/apis';
import Popup from 'reactjs-popup';
import './popup.css';
import { useAuth } from 'src/sessions/Session';

const statusList = [
  'pending',
  'running',
  'complete_success',
  'complete_error',
  'complete_partial_error'
];

const CrawlbackStatus = ({ from, to }) => {
  const [result, invokeStatus] = useGetCrawlbackStatus();
  const [refresh, setRefresh] = useState(null);
  useEffect(() => {
    async function load() {
      const result = await invokeStatus(
        from.format('YYYY-MM-DD'),
        to.format('YYYY-MM-DD')
      );
      setTimeout(() => setRefresh(new Date().getTime()), 10000);
    }
    load();
  }, [refresh]);
  return (
    <div>
      <div>Crawlback Status</div>
      {statusList.map((name) => {
        const [status, subStatus1, subStatus2] = name.split('_');
        const subStatus = [subStatus1, subStatus2].filter((i) => !!i).join('_');
        const item = result?.data?.find((tuple) => {
          return (
            tuple.status == status &&
            (subStatus ? tuple.subStatus == subStatus : true)
          );
        });
        const count = item?.count || 0;
        return (
          <div key={name}>
            {subStatus ? subStatus : status}: {count}
          </div>
        );
      })}
    </div>
  );
};

export default CrawlbackStatus;
