import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useMonitoringGetPostMadeWithReplyCount,
  useGetObjectList
} from 'src/apis';
import DateRangePicker from 'src/components/DateRangePicker';
import { Table, Button } from 'src/components';
import Select from 'react-select';

const ShowInstagramReply = ({ projectId, projectName, onClose }) => {
  const [objectList, invokeGetObjectList] = useGetObjectList();
  const [streamList, invokeGet] = useMonitoringGetPostMadeWithReplyCount();
  const [selectedObjectList, setObjectList] = useState([]);
  const [timeFrame, setTimeFrame] = useState({
    start: moment().startOf('day').subtract(7, 'day'),
    end: moment().startOf('day').add(1, 'day')
  });
  useEffect(() => {
    async function load() {
      await invokeGetObjectList(projectId);
    }
    load();
  }, []);
  const streamColumns = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'stream.originalId',
        width: 100
      },
      {
        id: 'link',
        Header: 'Link',
        accessor: (row) => {
          return (
            <a target="_blank" href={row.stream.link}>
              {row.stream.link}
            </a>
          );
        },
        width: 100
      },
      {
        id: 'fromId',
        Header: 'From Id',
        accessor: 'stream.fromId',
        width: 100
      },
      {
        id: 'fromName',
        Header: 'From Name',
        accessor: 'stream.fromName',
        width: 100
      },
      {
        id: 'content',
        Header: 'Content',
        accessor: 'stream.content',
        width: 100
      },
      {
        id: 'comment',
        Header: 'Comment (from post)',
        accessor: 'stream.commentCount',
        width: 100
      },
      {
        id: 'talk',
        Header: 'Comment (from talk)',
        accessor: (row) => {
          return row.replyCount || 0;
        },
        width: 100
      }
    ],
    []
  );
  const realData = streamList?.data ? streamList.data : [];
  const [table, checked] = Table({
    showCheckbox: false,
    data: realData,
    totalSize: realData.length,
    columns: streamColumns,
    onSearch: (item, phrase) => {
      return (
        item.stream.content.toLowerCase().indexOf(phrase.toLowerCase()) >= 0 ||
        item.stream.fromName.toLowerCase().indexOf(phrase.toLowerCase()) >= 0 ||
        item.stream.fromId.toLowerCase().indexOf(phrase.toLowerCase()) >= 0
      );
    }
  });
  const objectListSelections = objectList.data
    ? objectList.data
        .filter((item) => {
          return (
            item.streamType == 'account'
          );
        })
        .map((item) => {
          return {
            value: item,
            label: `${item.socialMedia} ${item.objectName}`
          };
        })
    : [];
  console.log(objectList);
  return (
    <>
      <div>Project {projectName}</div>
      <div className="col-span-8 p-1">
        <DateRangePicker
          onChange={(update) => {
            setTimeFrame({
              start: update.start,
              end: update.end
            });
          }}
          start={timeFrame.start}
          end={timeFrame.end}
        />
        <Select
          isMulti
          options={objectListSelections}
          onChange={(item, action) => {
            console.log(item);
            setObjectList(item.map((value) => value.value));
          }}
        ></Select>
      </div>

      <div className="col-span-8 p-1">{table}</div>
      <Button
        disabled={selectedObjectList.length == 0 || streamList.loading}
        onClick={async () => {
          await invokeGet(
            projectId,
            timeFrame.start.format('YYYY-MM-DD'),
            timeFrame.end.format('YYYY-MM-DD'),
            selectedObjectList.map((item) => item.uuid)
          );
        }}
      >
        {streamList.loading ? 'Processing..' : 'Load'}
      </Button>
      <Button onClick={() => onClose()}>Close</Button>
    </>
  );
};

export default ShowInstagramReply;
