import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from '../../sessions/Session';
import { LOGIN } from '../../routers/paths';

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    component: Component,
    requiresAuthorization,
    roles,
    path,
    ...rest
  } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { isAuthenticated, user, currentRole } = useAuth();
  // const isAuthorized = roles ? !!roles.find(item => currentRole == item) : false
  const isAuthorized = isAuthenticated;
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (requiresAuthorization && !isAuthenticated) {
          window.location.assign(LOGIN);
          return null;
        } else if (requiresAuthorization && isAuthenticated) {
          return (
            <Layout>
              {isAuthorized ? (
                <Component {...matchProps} />
              ) : (
                <div>You are not allowed to access this page</div>
              )}
            </Layout>
          );
        } else {
          return (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          );
        }
      }}
    />
  );
};

export default RouteWithLayout;
