import React from 'react';

const Button = (props) => {
  const { pageNumber, total, setPageNumber, currentSize } = props;
  const totalPage = Math.ceil(total / 10);
  const arrayFills = Array(totalPage).fill(0, 0, totalPage);

  return (
    <div class="flex items-center justify-between px-0 py-3 bg-white border-t border-gray-200 sm:px-0">
      <div class="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          class="relative inline-flex items-center px-4 py-2 hover:text-gray-500 text-gray-700 text-sm font-medium bg-white border border-gray-300 rounded-md"
        >
          Previous
        </a>
        <a
          href="#"
          class="relative inline-flex items-center ml-3 px-4 py-2 hover:text-gray-500 text-gray-700 text-sm font-medium bg-white border border-gray-300 rounded-md"
        >
          Next
        </a>
      </div>
      <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p class="text-gray-700 text-sm">
            Showing
            <span class="px-1 font-medium">
              {total > 0 ? (pageNumber - 1) * 10 + 1 : 0}
            </span>
            to
            <span class="px-1 font-medium">
              {(pageNumber - 1) * 10 + currentSize}
            </span>
            of
            <span class="px-1 font-medium">{total}</span>
            results
          </p>
        </div>
        <div>
          <nav
            class="relative z-0 inline-flex shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                if (pageNumber > 1) {
                  setPageNumber(pageNumber - 1);
                }
              }}
              href="#"
              class="relative inline-flex items-center px-2 py-2 text-gray-500 text-sm font-medium hover:bg-gray-50 bg-white border border-gray-300 rounded-l-md"
            >
              <span class="sr-only">Previous</span>
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
            <select onChange={(e) => setPageNumber(parseInt(e.target.value))}>
              {arrayFills.map((v, index) => {
                return (
                  <option
                    selected={index + 1 == pageNumber ? 'selected' : null}
                    value={index + 1}
                  >
                    {index + 1}
                  </option>
                );
              })}
            </select>
            <a
              onClick={(e) => {
                e.preventDefault();
                if (pageNumber * 10 < total) {
                  setPageNumber(pageNumber + 1);
                }
              }}
              href="#"
              class="relative inline-flex items-center px-2 py-2 text-gray-500 text-sm font-medium hover:bg-gray-50 bg-white border border-gray-300 rounded-r-md"
            >
              <span class="sr-only">Next</span>
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Button;
