import React from 'react';

const Input = (props) => {
  return (
    <div className="pt-0">
      {props.type == 'textarea' ? (
        <textarea
          className="placeholder-gray-400 focus:shadow-outline relative px-3 py-3 w-full text-gray-700 text-sm bg-white rounded outline-none focus:outline-none shadow"
          {...props}
        >
          {props.value}
        </textarea>
      ) : (
        <input
          type={props.type || 'text'}
          {...props}
          className="placeholder-gray-400 focus:shadow-outline relative px-3 py-3 w-full text-gray-700 text-sm bg-white rounded outline-none focus:outline-none shadow"
        />
      )}
    </div>
  );
};

export default Input;
