import React from 'react';
import { ButtonText, Button } from 'src/components';
import { useHistory } from 'react-router-dom';
import {
  CLUSTER_STATUS,
  LEADER_LIST,
  WORKER_LIST,
  PROJECT_LIST,
  CRAWLBACK_PANEL,
  QUEUE_LIST,
  COMMENT_CRAWLBACK
} from 'src/routers/paths';
import { useAuth } from 'src/sessions/Session';

const paths = [
  // {
  //   name: 'Cluster Status',
  //   path: CLUSTER_STATUS,
  //   roles: ['admin']
  // },

  {
    name: 'Project List',
    path: PROJECT_LIST,
    roles: ['admin']
  },
  {
    name: 'Crawlback Panel',
    path: CRAWLBACK_PANEL,
    roles: ['admin']
  },
  {
    name: 'Leader List',
    path: LEADER_LIST,
    roles: ['admin']
  },
  {
    name: 'Queue List',
    path: QUEUE_LIST,
    roles: ['admin']
  },
  {
    name: 'Worker List',
    path: WORKER_LIST,
    roles: ['admin']
  },
  {
    name: 'Comment Crawlback',
    path: COMMENT_CRAWLBACK,
    roles: ['admin']
  }
];

const Header = (props) => {
  const history = useHistory();
  const { signOut, currentRole } = useAuth();
  return (
    <div className="grid grid-cols-12 bg-indigo-900">
      <div className="flex col-span-1 items-center justify-center">
        <img
          src="https://dashboard.nolimit.id/static/media/logoNoLimitWhite.5cbf133e.svg"
          alt="nolimitid-logo"
          className="relative mb-2 mt-2 w-auto h-12"
        />
      </div>
      <div className="flex col-span-11 justify-between bg-secondary">
        <div className={'lg:flex items-center' + (false ? ' flex' : ' hidden')}>
          <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
            {paths.map((config) => {
              const currentPath = history.location.pathname == config.path;
              return (
                <li className="nav-item" key={config.path}>
                  <ButtonText
                    className={
                      'flex items-center px-6 py-2 ' +
                      (currentPath ? 'underline' : '') +
                      ' text-white text-xs leading-snug outline-none focus:outline-none hover:opacity-75 uppercase'
                    }
                    onClick={() => history.push(config.path)}
                  >
                    {config.name}
                  </ButtonText>
                </li>
              );
            })}
          </ul>
        </div>
        <Button>
          <a
            href="#"
            onClick={async () => {
              await signOut();
            }}
          >
            Logout
          </a>
        </Button>
      </div>
    </div>
  );
};

export default Header;
