import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import './styles.css';
import Pagination from '../Pagination';
import Input from '../Input';
import { useRef } from 'react';

const Table = ({
  columns,
  data,
  pushColumn = [
    {
      id: 'number',
      Header: () => <div>No</div>,
      Cell: ({ row }) => <div>{row.realIndex}</div>
    }
  ],
  fetchData = null,
  pageSize = 10,
  totalSize = 0,
  pageIndex = 0,
  showCheckbox,
  onSearch,
  rowId = (row) => row.id
}) => {
  const [checked, setChecked] = useState({});
  const [isCheckAll, setCheckAll] = useState(false);
  const [paging, setPaging] = useState({
    pageIndex: pageIndex ? pageIndex : 0,
    pageSize: pageSize ? pageSize : 10
  });
  const [externalReset, setExternalReset] = useState(new Date().getTime());
  const [currentPhrase, setPhrase] = useState('');
  const refChecked = useRef({});
  let resetChecked = () => {
    setCheckAll(false);
    setExternalReset(new Date().getTime());
  };
  useEffect(() => {
    if (isCheckAll) {
      let newState = {};
      data.forEach((value, idx) => {
        const realIdx = rowId ? rowId(value) : idx;
        newState[realIdx] = value;
      });
      refChecked.current = newState;
      setChecked(newState);
    } else {
      refChecked.current = {};
      setChecked((current) => ({}));
    }
  }, [isCheckAll, externalReset]);
  let checkboxColumn = [];
  if (showCheckbox) {
    checkboxColumn = [
      {
        id: 'checkbox',
        Header: () => (
          <div style={{ width: 20 }}>
            <input
              type="checkbox"
              onChange={() => {
                setCheckAll((current) => !current);
              }}
            />
          </div>
        ),
        Cell: (element) => {
          const { row } = element;
          const id = rowId ? rowId(row.original) : row.id;
          return (
            <div style={{ width: 20 }}>
              <input
                type="checkbox"
                checked={!!refChecked.current[id]}
                onChange={(e) => {
                  setChecked((current) => {
                    const newState = {
                      ...current,
                      [id]: current[id] ? null : row.original
                    };
                    refChecked.current = newState;
                    return newState;
                  });
                }}
              />
            </div>
          );
        }
      }
    ];
  }

  const [realTotalSize, realData] = fetchData
    ? [totalSize, data]
    : ((data, currentPhrase) => {
        let filtered = data;
        if (currentPhrase && onSearch) {
          filtered = filtered.filter((item) => onSearch(item, currentPhrase));
        }
        return [
          filtered.length,
          filtered.slice(
            paging.pageIndex * paging.pageSize,
            (paging.pageIndex + 1) * paging.pageSize
          )
        ];
      })(data, currentPhrase);
  const pageCount = Math.ceil(realTotalSize / paging.pageSize);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    gotoPage
  } = useTable(
    {
      columns,
      data: realData,
      manualSortBy: true,
      disableMultiSort: true,
      manualPagination: true,
      pageSize: paging.pageSize,
      pageIndex: paging.pageIndex,
      pageCount: pageCount || 1,
      autoResetPage: true
    },
    useSortBy,
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        ...[...pushColumn],
        ...[...checkboxColumn],
        ...columns
      ]);
    }
  );
  useEffect(() => {
    if (fetchData) {
      refChecked.current = {};
      setChecked((current) => {
        return {};
      });
      fetchData(state);
    } else {
      setPaging({
        pageIndex: state.pageIndex,
        pageSize: state.pageSize
      });
    }
  }, [JSON.stringify(state)]);
  const element = (
    <div>
      {onSearch && (
        <div className="p-6">
          <div className="grid grid-cols-4">
            <div className="col-span-1 p-1">Search</div>
            <div className="col-span-3 p-3">
              <Input
                type="text"
                value={currentPhrase}
                onChange={(event) => setPhrase(event.target.value)}
              />
            </div>
          </div>
        </div>
      )}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!fetchData && (
          <tbody {...getTableBodyProps()}>
            {rows.slice(0, paging.pageSize).map((row, i) => {
              prepareRow(row);
              row.realIndex = row.index + paging.pageIndex * 10 + 1;
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
        {fetchData && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              row.realIndex = row.index + pageIndex * 10 + 1;
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {!fetchData && (
        <Pagination
          pageNumber={paging.pageIndex + 1}
          total={realTotalSize}
          setPageNumber={(value) => {
            gotoPage(value - 1);
          }}
          currentSize={realData.length}
        />
      )}
      {fetchData && (
        <Pagination
          pageNumber={pageIndex + 1}
          total={realTotalSize}
          setPageNumber={(value) => {
            gotoPage(value - 1);
          }}
          currentSize={realData.length}
        />
      )}
    </div>
  );
  return [element, Object.values(checked || {}).filter((i) => i), resetChecked];
};

export default Table;
