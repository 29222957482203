import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useReassignWorkerToQueue } from 'src/apis';
import { Table, Button } from 'src/components';

const QueueNameDropdown = ({ queueName, workerId, queueList }) => {
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [reassignResult, performReassign] = useReassignWorkerToQueue();
  const [currentQueue, setCurrentQueue] = useState(
    queueList.find((item) => item.configuration.queueName == queueName)
  );
  console.log('Reassign', currentQueue, selectedQueue);
  return (
    <div>
      <select
        value={selectedQueue || currentQueue?.configuration.id}
        onChange={(e) => setSelectedQueue(e.target.value)}
      >
        <option>--no queue--</option>
        {queueList.map((item) => {
          return (
            <option key={item.configuration.id} value={item.configuration.id}>
              {item.configuration.queueName}
            </option>
          );
        })}
      </select>
      {selectedQueue && currentQueue?.configuration.id != selectedQueue && (
        <div>
          <div>Current: {currentQueue?.configuration.queueName}</div>
          <Button
            disabled={reassignResult?.loading}
            onClick={async () => {
              try {
                const result = performReassign(workerId, selectedQueue);
                console.log('Result from reassign', result);
                setCurrentQueue(
                  queueList.find(
                    (item) => item.configuration.id == selectedQueue
                  )
                );
                setSelectedQueue(null);
              } catch (e) {
                console.log('Error on reassign queue', e);
              }
            }}
          >
            Apply change
          </Button>{' '}
          <Button onClick={() => setSelectedQueue(null)}>Cancel</Button>
        </div>
      )}
    </div>
  );
};

export default QueueNameDropdown;
