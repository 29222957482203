import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import {
  useGetCrawlbackJobDetails,
  useCancelCrawlbackJob,
  useCancelCrawlbackJobFromDashboard,
  useRetryCrawlbackJobFromDashboard
} from 'src/apis';
import { Table, Button } from 'src/components';
import DateRangePicker from 'src/components/DateRangePicker';
import CreateGroupJob from './CreateGroupJob';
import CrawlbackStatus from './CrawlbackStatus';
import Filter from './Filter';
import ButtonWithConfirmation from 'src/components/ButtonWithConfirmation/ButtonWithConfirmation';
import ReactTooltip from 'react-tooltip';

const CrawlbackJobDetails = ({ jobId }) => {
  const [cancelResult, performCancel] = useCancelCrawlbackJobFromDashboard();
  const [retryResult, performRetry] = useRetryCrawlbackJobFromDashboard();
  const [refresh, setRefresh] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null)
  const handleSelectByStatusChanges = (event) => {
    const value = event.target.value
    setSelectedStatus(value == 'all' ? null : value)
  }
  const triggerRefresh = () => setRefresh(new Date().getTime());
  const columns = useMemo(
    () => [
      {
        id: 'stream_type',
        Header: 'Stream Type',
        accessor: 'objectModel.streamType',
        width: 100
      },
      {
        id: 'social_media',
        Header: 'Social Media',
        accessor: 'objectModel.socialMedia',
        width: 100
      },
      {
        id: 'object_name',
        Header: 'Object Name',
        accessor: 'objectModel.objectName',
        width: 100
      },
      {
        id: 'content',
        Header: 'Content',
        accessor: 'objectModel.content',
        width: 100
      },
      {
        id: 'extra_content',
        Header: 'Extra Content',
        accessor: 'objectModel.extraContent',
        width: 100
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (row) => {
          const status = row.job.status
          if(status == 'pending') {
            if(row.job.crawlerJobId) return 'waiting for worker'
            else return status
          } else return status
        },
        width: 100
      },
      {
        id: 'count',
        Header: 'Count',
        accessor: 'job.count',
        width: 100
      },
      {
        id: 'error',
        Header: 'Error',
        accessor: (row) => {
          return (
            row.job.error && (
              <>
                <span data-tip data-for={row.job.uuid}>
                  {' '}
                  {row.job.error?.slice(0, 10)}...{' '}
                </span>
                <ReactTooltip id={row.job.uuid} type="error">
                  <span>{row.job.error}</span>
                </ReactTooltip>
              </>
            )
          );
        },
        width: 100
      },
      {
        id: 'toolbar',
        Header: 'Toolbar',
        accessor: (row) => {
          let toolbars = [];

          if (row.job.status == 'pending' || row.job.status == 'running') {
            toolbars.push(
              <ButtonWithConfirmation
                text="Cancel"
                onOk={async () => {
                  console.log('Begin cancelling', row.job);
                  const result = await performCancel(row.job.uuid);
                  console.log('Cancel result', result);
                  triggerRefresh();
                  return result;
                }}
              />
            );
          }
          if (row.job.status == 'error') {
            toolbars.push(
              <ButtonWithConfirmation
                text="Retry"
                onOk={async () => {
                  console.log('Begin retrying', row.job);
                  const result = await performRetry(row.job.uuid);
                  console.log('Retry result', result);
                  triggerRefresh();
                  return result;
                }}
              />
            );
          }
          return <div>{toolbars}</div>;
        }
      }
    ],
    []
  );
  const [result, invokeDetails] = useGetCrawlbackJobDetails(jobId);
  useEffect(() => {
    async function load() {
      const result = await invokeDetails();
    }
    load();
  }, [refresh]);
  const baseData = result?.data ? result.data : []
  const realData = baseData.filter(item => {
    if(!selectedStatus) return true
    else {
      switch(selectedStatus) {
        case "pending": 
          return item.job.status == 'pending' && !item.job.crawlerJobId
        case "waiting": 
          return item.job.status == 'pending' && item.job.crawlerJobId
        case "running": 
          return item.job.status == 'running'
        case "success":
          return item.job.status == 'success'
        case "error": 
          return item.job.status == 'error'
        default:
          return false
          
      } 
    }
  });
  const [table] = Table({
    showCheckbox: false,
    data: realData,
    totalSize: realData.length,
    columns: columns,
    onSearch: (item, phrase) => {
      return (
        item.objectModel.content.toLowerCase().indexOf(phrase.toLowerCase()) >=
          0 ||
        item.objectModel.streamType
          .toLowerCase()
          .indexOf(phrase.toLowerCase()) >= 0 ||
        item.objectModel.socialMedia
          .toLowerCase()
          .indexOf(phrase.toLowerCase()) >= 0 ||
        item.objectModel.objectName
          .toLowerCase()
          .indexOf(phrase.toLowerCase()) >= 0 ||
        item.job.status.toLowerCase().indexOf(phrase.toLowerCase()) >= 0
      );
    }
  });
  let retryAllButton = null;
  let cancelAllButton = null;
  const errorList = realData.filter((item) => item.job.status == 'error');
  const nonCompleteList = realData.filter(
    (item) => item.job.status == 'running' || item.job.status == 'pending'
  );
  if (errorList.length > 0) {
    retryAllButton = (
      <ButtonWithConfirmation
        text={`Retry All ${errorList.length}`}
        onOk={async () => {
          await errorList.reduce(async (memo, item) => {
            await memo;
            const result = await performRetry(item.job.uuid);
            return result;
          }, Promise.resolve());
          triggerRefresh();
        }}
      />
    );
  }
  if (nonCompleteList.length > 0) {
    cancelAllButton = (
      <ButtonWithConfirmation
        text={`Cancel All ${nonCompleteList.length}`}
        onOk={async () => {
          await nonCompleteList.reduce(async (memo, item) => {
            await memo;
            const result = await performCancel(item.job.uuid);
            return result;
          }, Promise.resolve());
          triggerRefresh();
        }}
      />
    );
  }
  const refreshButton = (
    <Button onClick={() => triggerRefresh()}>Refresh</Button>
  );
  const pendingCount = baseData.filter(item => item.job.status == 'pending' && !item.job.crawlerJobId).length
  const waitingCount = baseData.filter(item => item.job.status == 'pending' && item.job.crawlerJobId).length
  const runningCount = baseData.filter(item => item.job.status == 'running' ).length
  const successCount = baseData.filter(item => item.job.status == 'success' ).length
  const errorCount = baseData.filter(item => item.job.status == 'error' ).length
  const selectByStatuses = <select onChange={handleSelectByStatusChanges}>
    <option value='all'>All ({realData.length})</option>
    <option value='pending'>Pending ({pendingCount})</option>
    <option value='waiting'>Waiting for worker ({waitingCount})</option>
    <option value='running'>Running ({runningCount})</option>
    <option value='success'>Success ({successCount})</option>
    <option value='error'>Error ({errorCount})</option>
  </select>
  return (
    <div style={{'padding': '5px'}}>
      <div>
        {refreshButton} {retryAllButton} {cancelAllButton} {selectByStatuses}
      </div>
      <div>{table}</div>
    </div>
  );
};

export default CrawlbackJobDetails;
