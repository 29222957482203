export const ApiBaseUrl = 'https://crawlercluster.dashboard.nolimit.id';
export const DashboardApiUrl = 'https://v5.external.dashboard.nolimit.id';
export const LoginBaseUrl = 'https://dto.backend.validator.nolimit.id';
export const PATHS = {
  USER_LOGIN: DashboardApiUrl + '/monitoring/user/login',
  USER_ME: DashboardApiUrl + '/monitoring/user/me',
  USER_LOGOUT: DashboardApiUrl + '/monitoring/user/logout',
  GET_LEADER_LIST: ApiBaseUrl + '/periodic-job/get-leader-list',
  GET_CONFIG_LIST: ApiBaseUrl + '/periodic-job/config/list',
  GET_WORKER_LIST: ApiBaseUrl + '/workers/list',
  GET_CLUSTER_STATUS: ApiBaseUrl + '/cluster/status',
  GET_PROJECT_LIST: DashboardApiUrl + '/project-management/projects/list',
  GET_OBJECT_LIST: DashboardApiUrl + '/project-management/projects/object-list',
  UPDATE_PROJECT_TO_CRAWLER:
    DashboardApiUrl + '/project-management/projects/register-to-crawler',
  GET_CRAWLBACK_STATUS: DashboardApiUrl + '/crawlback/get-status',
  GET_CRAWLBACK_LIST: DashboardApiUrl + '/crawlback/group-crawlback-job/list',
  GET_CRAWLBACK_JOB_DETAILS:
    DashboardApiUrl + '/crawlback/group-crawlback-job/{id}/details',
  CREATE_GROUP_CRAWLBACK_JOB:
    DashboardApiUrl + '/crawlback/group-crawlback-job/create',
  UPDATE_WORKER_QUEUE: ApiBaseUrl + '/workers/reassign-to-queue',
  CANCEL_CRAWLBACK_JOB_VIA_CRAWLER: ApiBaseUrl + '/on-demand-job/cancel',
  CANCEL_CRAWLBACK_JOB_VIA_DASHBOARD:
    DashboardApiUrl + '/crawlback/crawlback-job/cancel',
  RETRY_CRAWLBACK_JOB_VIA_DASHBOARD:
    DashboardApiUrl + '/crawlback/crawlback-job/retry',
  MONITORING_GET_POST_MADE_WITH_REPLY:
    DashboardApiUrl + '/monitoring/compute/post-made-with-reply-count',
  CREATE_ENGAGEMENT_JOB: DashboardApiUrl + '/crawlback/engagement-job/create',
  GET_ENGAGEMENT_JOB_LIST: DashboardApiUrl + '/crawlback/engagement-job/list',
  RETRY_ENGAGEMENT_JOB: DashboardApiUrl + '/crawlback/engagement-job/retry',
  CANCEL_ENGAGEMENT_JOB: DashboardApiUrl + '/crawlback/engagement-job/cancel'
};
