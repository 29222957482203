import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useGetConfigList, useGetLeaderList, useGetWorkerList } from 'src/apis';
import { Table, Button } from 'src/components';
import QueueNameDropdown from './QueueNameDropdown';
const WorkerList = () => {
  const [configList] = useGetConfigList();
  console.log(configList);
  const columns = React.useMemo(
    () => [
      {
        id: 'address',
        Header: 'Address',
        accessor: (row) => {
          return row.hostname || row.address;
        },
        width: 100
      },
      {
        id: 'worker_type',
        Header: 'Type',
        accessor: 'workerType',
        width: 100
      },
      {
        id: 'sub_worker_type',
        Header: 'Sub Type',
        accessor: 'subworkerType',
        width: 100
      },
      {
        id: 'queue_name',
        Header: 'Queue Name',
        accessor: (row) => row,
        Cell: (table) => {
          const row = table.cell.value;
          return (
            <QueueNameDropdown
              key={row.id}
              queueName={row.queueName}
              workerId={row.id}
              queueList={configList?.data || []}
            />
          );
        },
        width: 100
      },
      {
        id: 'capacity',
        Header: 'Capacity',
        accessor: 'capacity',
        width: 100
      },
      {
        id: 'used_capacity',
        Header: 'Used Capacity',
        accessor: 'occupiedCapacity',
        width: 100
      },
      {
        id: 'liveliness',
        Header: 'Liveliness',
        accessor: 'liveliness',
        width: 100
      },
      {
        id: 'last_heartbeat',
        Header: 'Last Heartbeat',
        accessor: (row) => {
          return moment(row.lastHeartbeat).format('YYYY-MM-DD HH:mm');
        },
        width: 100
      }
    ],
    [configList?.data]
  );
  const [result, invokeGetWorkerList] = useGetWorkerList(true);
  useEffect(() => {
    async function load() {
      const result = await invokeGetWorkerList();
      //   console.log(result);
    }
    load();
  }, []);
  const [table, checked] = Table({
    showCheckbox: false,
    data: result.data ? result.data : [],
    totalSize: result.data ? result.data.length : 0,
    columns
  });
  return (
    <div className="p-6 bg-white">
      {table}
      <Button onClick={() => invokeGetWorkerList()}>Reload</Button>
    </div>
  );
};

export default WorkerList;
