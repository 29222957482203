import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useGetConfigList, useGetLeaderList, useGetWorkerList } from 'src/apis';
import { Table, Button } from 'src/components';
import CreateNewQueue from './CreateNewQueue';
const WorkerList = () => {
  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'configuration.queueName',
        width: 100
      },
      {
        id: 'rule',
        Header: 'Rule',
        accessor: 'configuration.timeFrameConfiguration.rule',
        width: 100
      },
      {
        id: 'is_periodic',
        Header: 'Queue Type',
        accessor: (item) => {
          if (item.configuration.isPeriodic) return 'Daily';
          else return 'Crawlback';
        },
        width: 100
      },

      {
        id: 'interval',
        Header: 'Interval (in hour)',
        accessor: 'configuration.interval',
        width: 100
      },
      {
        id: 'max_capacity',
        Header: 'Max Capacity',
        accessor: 'capacity.maxCapacity',
        width: 100
      },
      {
        id: 'used_capacity',
        Header: 'Used Capacity',
        accessor: 'capacity.usedCapacity',
        width: 100
      }
    ],
    []
  );
  const [result, invokeQueueList] = useGetConfigList(true);
  useEffect(() => {
    async function load() {
      const result = await invokeQueueList();
      //   console.log(result);
    }
    load();
  }, []);
  const [table, checked] = Table({
    showCheckbox: false,
    data: result.data ? result.data : [],
    totalSize: result.data ? result.data.length : 0,
    columns
  });
  return (
    <div className="p-6 bg-white">
      {table}
      {/* <CreateNewQueue /> */}
      <Button onClick={() => invokeQueueList()}>Reload</Button>
    </div>
  );
};

export default WorkerList;
