import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import DateRangePicker from 'src/components/DateRangePicker';
import { Button, Table } from 'src/components';
import {
  useGetProjectList,
  useGetObjectList,
  useGetConfigList,
  useCreateNewGroupCrawlbackJob
} from 'src/apis';
import Popup from 'reactjs-popup';
import './popup.css';
import { useAuth } from 'src/sessions/Session';

const isOkToSubmit = (projectId, objectList, queueName) => {
  return !!projectId && objectList.length > 0 && !!queueName;
};

const CreateGroupJob = ({ onSuccess, onError }) => {
  const { user } = useAuth();
  const [configList, invokeGetConfig] = useGetConfigList(true);

  const objectColumns = useMemo(
    () => [
      {
        id: 'stream_type',
        Header: 'Stream Type',
        accessor: 'streamType',
        width: 100
      },
      {
        id: 'social_media',
        Header: 'Social Media',
        accessor: 'socialMedia',
        width: 100
      },
      {
        id: 'object_name',
        Header: 'Object Name',
        accessor: 'objectName',
        width: 100
      },
      {
        id: 'content',
        Header: 'Content',
        accessor: 'content',
        width: 100
      },
      {
        id: 'extra_content',
        Header: 'Extra Content',
        accessor: 'extraContent',
        width: 100
      }
    ],
    []
  );
  const [projectList, invokeProjectList] = useGetProjectList(false);
  const [selectedProjectId, setProject] = useState(null);
  const [objectResult, invokeObjectGet] = useGetObjectList();
  const [createJobResult, invokeCreateJob] = useCreateNewGroupCrawlbackJob();
  const [selectedQueue, setQueue] = useState('');
  const [timeFrame, setTimeFrame] = useState({
    start: moment().startOf('day').subtract(7, 'day'),
    end: moment().startOf('day').add(1, 'day')
  });
  useEffect(() => {
    async function load() {
      resetChecked();
      if (!selectedProjectId) return;
      const result = await invokeObjectGet(selectedProjectId);
    }
    load();
  }, [selectedProjectId]);
  const objectRealData = objectResult.data
    ? objectResult.data.sort((a, b) => {
        const aTs = moment(a.createdAt).valueOf();
        const bTs = moment(b.createdAt).valueOf();
        if (aTs > bTs) return -1;
        else if (aTs < bTs) return 1;
        else return 0;
      })
    : [];
  const [objectTable, checked, resetChecked] = Table({
    showCheckbox: true,
    data: objectRealData,
    totalSize: objectRealData.length,
    columns: objectColumns,
    onSearch: (item, phrase) => {
      return (
        item.content.toLowerCase().indexOf(phrase.toLowerCase()) >= 0 ||
        item.streamType.toLowerCase().indexOf(phrase.toLowerCase()) >= 0 ||
        item.socialMedia.toLowerCase().indexOf(phrase.toLowerCase()) >= 0 ||
        item.objectName.toLowerCase().indexOf(phrase.toLowerCase()) >= 0
      );
    },
    rowId: (row) => {
      return row.uuid;
    }
  });
  return (
    <Popup
      modal={true}
      trigger={<Button>Create New Jobs</Button>}
      className="create-group-job-popup"
      closeOnDocumentClick={true}
      onClose={(event) => console.log('close', event)}
      onOpen={() => invokeGetConfig()}
    >
      {(close) => (
        <div style={{ width: '100%' }}>
          <div>
            <select
              value={selectedQueue}
              onChange={(e) => setQueue(e.target.value)}
            >
              <option>Select queue</option>
              {configList?.data
                ?.filter((item) => !item.configuration.isPeriodic)
                .map(({ configuration, capacity }) => {
                  const percentageCapacity = capacity
                    ? (
                        (capacity.usedCapacity / capacity.maxCapacity) *
                        100
                      ).toFixed(2) + '%'
                    : 'no worker available';
                  return (
                    <option
                      disabled={capacity ? false : true}
                      value={configuration.queueName}
                    >
                      {configuration.queueName} - {percentageCapacity}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <select
              value={selectedProjectId}
              onChange={(e) => setProject(e.target.value)}
            >
              <option>Select project</option>
              {projectList?.data?.map((project) => {
                return (
                  <option value={project.uuid}>{project.displayName}</option>
                );
              })}
            </select>
          </div>
          <div>
            <DateRangePicker
              onChange={(update) => {
                setTimeFrame({
                  start: update.start,
                  end: update.end
                });
              }}
              start={timeFrame.start}
              end={timeFrame.end}
            />
          </div>
          <div>{objectTable}</div>
          <Button
            disabled={!isOkToSubmit(selectedProjectId, checked, selectedQueue)}
            onClick={async (e) => {
              try {
                console.log(
                  selectedProjectId,
                  user.userId,
                  checked.map((obj) => obj.uuid),
                  timeFrame.start,
                  timeFrame.end,
                  selectedQueue
                );
                const result = await invokeCreateJob(
                  selectedProjectId,
                  user.userId,
                  checked.map((obj) => obj.uuid),
                  timeFrame.start.format('YYYY-MM-DD'),
                  timeFrame.end.format('YYYY-MM-DD'),
                  selectedQueue
                );
                onSuccess && onSuccess(result);
              } catch (e) {
                onError && onError(e);
              }
              close();
            }}
          >
            Create
          </Button>
          <Button
            onClick={(e) => {
              close();
            }}
          >
            Close
          </Button>
        </div>
      )}
    </Popup>
  );
};

export default CreateGroupJob;
