const LOGIN = '/login';
const LEADER_LIST = '/leader-list';
const WORKER_LIST = '/worker-list';
const CLUSTER_STATUS = '/cluster-status';
const PROJECT_LIST = '/project-list';
const CRAWLBACK_PANEL = '/crawlback-panel';
const QUEUE_LIST = '/queue-list';
const COMMENT_CRAWLBACK = '/comment-crawlback';

export {
  LEADER_LIST,
  WORKER_LIST,
  CLUSTER_STATUS,
  PROJECT_LIST,
  CRAWLBACK_PANEL,
  LOGIN,
  QUEUE_LIST,
  COMMENT_CRAWLBACK
};
