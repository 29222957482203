import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useGetLeaderList,
  useGetWorkerList,
  useGetProjectList,
  useGetObjectList,
  useGetConfigList,
  useBulkUpdateQueueName
} from 'src/apis';
import { Table, Button } from 'src/components';
import DownloadDataReport from './DownloadDataReport';
import { ButtonExportExcel } from '@alckor127/react-button-export-excel';
import ShowInstagramReply from './ShowInstagramReply';
import '@alckor127/react-button-export-excel/dist/index.css';

const QueueNameSelection = ({ id, periodicJobQueueName, configList }) => {
  const [result, invokeUpdateQueueName] = useBulkUpdateQueueName();
  const [queueName, setQueueName] = useState(periodicJobQueueName);

  return (
    <select
      value={queueName}
      onChange={async (item) => {
        const queueName = item.target.value;
        setQueueName(queueName);
        await invokeUpdateQueueName(id, queueName);
      }}
    >
      <option value={'null'}>--no queue selected--</option>
      {configList.map(({ configuration }) => (
        <option key={configuration.queueName} value={configuration.queueName}>
          {configuration.queueName}
        </option>
      ))}
    </select>
  );
};

const trimText = (text) => {
  if (text.length > 20) return text.slice(0, 20) + '...';
  else return text;
};

const ProjectList = () => {
  const [activeList, setIsActiveList] = useState(true);
  const [selectedProject, setSelectedProject] = useState({
    project: null,
    subTask: null
  });
  const resetSelectedProject = () =>
    setSelectedProject({ project: null, subTask: null });
  const [configList] = useGetConfigList(false);

  const objectColumns = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'uuid',
        width: 100
      },
      {
        id: 'stream_type',
        Header: 'Stream Type',
        accessor: 'streamType',
        width: 100
      },
      {
        id: 'social_media',
        Header: 'Social Media',
        accessor: 'socialMedia',
        width: 100
      },
      {
        id: 'object_name',
        Header: 'Object Name',
        accessor: 'objectName',
        width: 100
      },
      {
        id: 'content',
        Header: 'Content',
        accessor: 'content',
        width: 100
      },
      {
        id: 'extra_content',
        Header: 'Extra Content',
        accessor: 'extraContent',
        width: 100
      }
    ],
    []
  );
  const projectColumns = React.useMemo(
    () =>
      [
        {
          id: 'id',
          Header: 'ID',
          accessor: 'id',
          width: 100
        },
        {
          id: 'name',
          Header: 'Name',
          accessor: (row) => trimText(row.name),
          width: 20
        },
        {
          id: 'display_name',
          Header: 'Display Name',
          accessor: (row) => trimText(row.displayName),
          width: 20
        },
        {
          id: 'plan_type',
          Header: 'Plan Type',
          accessor: 'planType',
          width: 100
        },
        {
          id: 'created_at',
          Header: 'Created At',
          accessor: (row) => {
            return moment(row.createdAt).format('YYYY-MM-DD HH:mm');
          },
          width: 100
        },
        {
          id: 'periodic_job_queue_name',
          Header: 'Queue Name',
          accessor: (row) => row,
          Cell: (table) => {
            const row = table.cell.value;
            return (
              <QueueNameSelection
                key={row.id}
                id={row.id}
                periodicJobQueueName={row.periodicJobQueueName}
                configList={configList?.data || []}
              />
            );
          },
          width: 100
        },
        {
          id: 'toolbar',
          Header: 'Toolbar',
          accessor: (row) => {
            const showObject = (
              <Button
                onClick={() =>
                  setSelectedProject({
                    project: row,
                    subTask: 'show_object'
                  })
                }
              >
                Show object
              </Button>
            );
            const showInstagramPanel = (
              <Button
                onClick={() =>
                  setSelectedProject({
                    project: row,
                    subTask: 'show_ig_comment'
                  })
                }
              >
                Check Comment
              </Button>
            );
            return (
              <>
                {showObject}
                {showInstagramPanel}
              </>
            );
          },
          width: 100
        }
      ].filter((item) => {
        if (selectedProject.project)
          return item.id == 'display_name' || item.id == 'toolbar';
        else return true;
      }),
    [selectedProject, configList?.data]
  );
  const [result, invokeGet] = useGetProjectList(true);
  const [objectResult, invokeObjectGet] = useGetObjectList();

  console.log(selectedProject);
  useEffect(() => {
    async function load() {
      const result = await invokeGet(activeList);
    }
    load();
  }, [activeList]);
  useEffect(() => {
    async function load() {
      if (!selectedProject.project) return;
      if (selectedProject.subTask != 'show_object') return;
      const result = await invokeObjectGet(selectedProject.project.id);
    }
    load();
  }, [JSON.stringify(selectedProject)]);

  console.log(objectResult);
  const realData = result.data
    ? result.data.sort((a, b) => {
        const aTs = moment(a.createdAt).valueOf();
        const bTs = moment(b.createdAt).valueOf();
        if (aTs > bTs) return -1;
        else if (aTs < bTs) return 1;
        else return 0;
      })
    : [];
  const [table, checked] = Table({
    showCheckbox: false,
    data: realData,
    totalSize: realData.length,
    columns: projectColumns,
    onSearch: (item, phrase) => {
      return (
        item.name.toLowerCase().indexOf(phrase.toLowerCase()) >= 0 ||
        item.displayName.toLowerCase().indexOf(phrase.toLowerCase()) >= 0
      );
    }
  });
  const objectRealData = objectResult.data
    ? objectResult.data.sort((a, b) => {
        const aTs = moment(a.createdAt).valueOf();
        const bTs = moment(b.createdAt).valueOf();
        if (aTs > bTs) return -1;
        else if (aTs < bTs) return 1;
        else return 0;
      })
    : [];
  const [objectTable] = Table({
    showCheckbox: false,
    data: objectRealData,
    totalSize: objectRealData.length,
    columns: objectColumns,
    onSearch: (item, phrase) => {
      return (
        item.content.toLowerCase().indexOf(phrase.toLowerCase()) >= 0 ||
        item.streamType.toLowerCase().indexOf(phrase.toLowerCase()) >= 0 ||
        item.socialMedia.toLowerCase().indexOf(phrase.toLowerCase()) >= 0 ||
        item.objectName.toLowerCase().indexOf(phrase.toLowerCase()) >= 0
      );
    }
  });
  return (
    <div className="p-6 bg-white">
      <div className="grid grid-cols-8">
        {/* <div>
        <select value={activeList} onChange={e => setIsActiveList(e.target.value)}>
          <option value={true}>Active</option>  
          <option value={false}>Not Active</option>
        </select>
      </div> */}

        {!selectedProject.project && (
          <>
            <div className={`col-span-8 p-1 `}>{table}</div>
            <Button onClick={() => invokeGet()}>Reload</Button>
          </>
        )}
        {selectedProject.project && selectedProject.subTask == 'show_object' && (
          <div className="col-span-8 p-1">
            <div>
              <div>
                <b>
                  {selectedProject.project.displayName} (
                  {selectedProject.project.id})
                </b>
              </div>
              <Button onClick={() => resetSelectedProject()}>Close</Button>
              <ButtonExportExcel
                data={objectRealData}
                filename={selectedProject.project.displayName + '_object.xlsx'}
              >
                Download Object List
              </ButtonExportExcel>
            </div>
            {objectTable}
          </div>
        )}
        {selectedProject.project &&
          selectedProject.subTask == 'show_ig_comment' && (
            <ShowInstagramReply
              onClose={() => resetSelectedProject()}
              projectId={selectedProject.project.id}
              projectName={selectedProject.project.displayName}
            />
          )}
      </div>
    </div>
  );
};

export default ProjectList;
