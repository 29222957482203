import React, { useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment from 'moment';
import './styles.css';

const DateInput = ({ onChange, start, end }) => {
  const [focusedInput, setFocusedInput] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    start: moment(start),
    end: moment(end)
  });
  const handleDatesChange = ({ startDate, endDate }) => {
    onChange &&
      onChange({
        start: startDate,
        end: endDate
      });
    setFocusedInput(false);
    setSelectedDate({
      start: startDate,
      end: endDate
    });
  };
  return (
    <div>
      <DateRangePicker
        onDatesChange={(tuple) => handleDatesChange(tuple)}
        focusedInput={focusedInput}
        onFocusChange={(update) => setFocusedInput(update)}
        withPortal
        showDefaultInputIcon={true}
        startDate={selectedDate.start}
        endDate={selectedDate.end}
        enableOutsideDays={true}
        isOutsideRange={() => false}
        displayFormat="YYYY-MM-DD"
        startDateId={new Date().toString()}
        endDateId={new Date().toString()}

        //orientation="vertical"
      />
    </div>
  );
};

export default DateInput;
