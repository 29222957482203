import React from 'react';

const Button = (props) => {
  let bgColorClass = props.disabled ? 'bg-gray-500' : 'bg-indigo-500';
  return (
    <button
      className={
        bgColorClass +
        ' text-white  font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none '
      }
      style={{ transition: 'all .15s ease' }}
      {...props}
      ref={props.useRef ? props.useRef : null}
    >
      {props.children}
    </button>
  );
};

export default Button;
