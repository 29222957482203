import React, { useState, createContext, useContext, useEffect } from 'react';
import { useUserMe, useLogout, useLogin } from 'src/apis';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function VerifyToken() {
  const [result, executeVerify] = useUserMe();
  const { saveUser, token, signOut, setToken } = useAuth();

  useEffect(() => {
    async function load() {
      console.log('Verify user');
      try {
        const result = await executeVerify();
        console.log('Verify user finish', result);
        if (result?.data?.authUser?.userId) {
          saveUser({ ...result.data.user, ...result.data.authUser });
        } else {
          //failed to verify user
          const x = await signOut();
        }
      } catch (e) {
        const x = await signOut();
      }
    }
    load();
  }, [token]);
  return <></>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [token, setToken] = useLocalStorage('token', null);
  const [loginResult, executeLogin] = useLogin();
  const [logoutResult, executeLogout] = useLogout();
  const [user, setUser] = useState(null);

  const signIn = async (username, password) => {
    const response = await executeLogin(username, password);
    if (response) {
      const { data: { authUser, user, token } = {} } = response;
      if (token) {
        setUser(user);
        setToken(token);
      }
    }
    return response;
  };

  const invalidateToken = () => setToken(null);
  const saveUser = (user) => setUser(user);
  const signOut = async () => {
    await executeLogout();
    setToken(null);
    setUser(null);
  };

  return {
    token,
    user,
    signIn,
    signOut,
    invalidateToken,
    saveUser,
    setToken,
    isAuthenticated: token ? true : false,
    currentRole: user ? 'admin' : null
  };
}

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      console.error(err);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (err) {
      console.error(err);
    }
  };

  return [storedValue, setValue];
};
