import { useFormik } from 'formik';
import React from 'react';
import { Button, Input } from 'src/components';
import { useRootContext } from 'src/contexts/RootContext/RootContext';
import validationSchema from './validationSchema';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'src/sessions/Session';
const Login = () => {
  const { handleSignIn } = useRootContext();
  const { isAuthenticated } = useAuth();
  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSignIn(values);
    }
  });
  const { handleSubmit, handleChange, values, errors, touched } = formik;
  console.log(isAuthenticated, 'is auth');
  if (isAuthenticated) {
    return <Redirect to="/" />;
  } else
    return (
      <div className="flex items-center justify-center w-full h-full">
        <div className="p-8 w-1/4 h-auto bg-white">
          <div className="flex justify-center w-full">
            <img
              src="https://dashboard.nolimit.id/static/media/image2.5d93db88.png"
              alt="nolimitid-logo"
              className="mb-8 w-auto h-24"
            />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="mb-4">
              <Input
                name="username"
                placeholder="username"
                onChange={handleChange}
                value={values.username}
              />
              {errors.username && touched.username && (
                <span className="text-red-500">{errors.username}</span>
              )}
            </div>
            <div className="">
              <Input
                name="password"
                placeholder="password"
                onChange={handleChange}
                value={values.password}
                type="password"
              />
              {errors.password && touched.password && (
                <span className="text-red-500">{errors.password}</span>
              )}
            </div>
            <div className="mt-4">
              <Button type="submit" style={{ width: '100%' }}>
                Sign in
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
};

export default Login;
