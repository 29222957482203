import React, { useState } from 'react';
import Button from '../Button';

const ButtonWithConfirmation = ({ text, onOk, ...rest }) => {
  const [waitingConfirmation, setWaiting] = useState(false);
  const [isOkInFlight, setInFlight] = useState(false);
  let element = null;
  if (!waitingConfirmation) {
    element = (
      <Button {...rest} onClick={() => setWaiting(true)}>
        {text}
      </Button>
    );
  } else {
    if (!isOkInFlight) {
      element = (
        <div>
          <div>Are you sure?</div>
          <Button
            disabled={isOkInFlight}
            onClick={async () => {
              setInFlight(true);
              try {
                const result = await onOk();
                setInFlight(false);
                setWaiting(false);
              } catch (e) {
                setInFlight(false);
              }
            }}
          >
            Yes
          </Button>
          <Button disabled={isOkInFlight} onClick={() => setWaiting(false)}>
            Cancel
          </Button>
        </div>
      );
    } else {
      element = <div>Please wait..</div>;
    }
  }

  return element;
};

export default ButtonWithConfirmation;
