import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import { useGetCrawlbackJobList } from 'src/apis';
import { Table, Button } from 'src/components';
import DateRangePicker from 'src/components/DateRangePicker';
import CreateGroupJob from './CreateGroupJob';
import CrawlbackStatus from './CrawlbackStatus';
import Filter from './Filter';
import CrawlbackJobDetails from './CrawlbackJobDetails';

const CrawlbackPanel = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const columns = useMemo(() => {
    if (selectedJob) {
      return [
        {
          id: 'project',
          Header: 'Project',
          accessor: 'project.displayName',
          width: 100
        },
        {
          id: 'status',
          Header: 'Status',
          accessor: 'group.status',
          width: 100
        },
        {
          id: 'toolbar',
          Header: 'Toolbar',
          accessor: (row) => {
            console.log('Row', row, selectedJob);
            if (selectedJob && selectedJob.group.uuid == row.group.uuid) {
              return (
                <Button onClick={() => setSelectedJob(null)}>Close</Button>
              );
            } else {
              return <Button onClick={() => setSelectedJob(row)}>Show</Button>;
            }
          }
        }
      ];
    } else
      return [
        {
          id: 'project',
          Header: 'Project',
          accessor: 'project.displayName',
          width: 100
        },
        {
          id: 'job_count',
          Header: 'Job Count',
          accessor: 'group.jobCount',
          width: 100
        },
        {
          id: 'stats',
          Header: 'Progress',
          width: 100,
          columns: [
            {
              Header: 'Pending',
              accessor: 'group.pendingCount'
            },
            {
              Header: 'Running',
              accessor: 'group.runningCount'
            },
            {
              Header: 'Success',
              accessor: 'group.successCount'
            },
            {
              Header: 'Error',
              accessor: 'group.errorCount'
            }
          ]
        },
        {
          id: 'timeFrame',
          Header: 'Time Frame',
          width: 100,
          columns: [
            {
              Header: 'From',
              accessor: (row) =>
                moment(row.group.timeFrameStart).format('YYYY-MM-DD')
            },
            {
              Header: 'To',
              accessor: (row) =>
                moment(row.group.timeFrameEnd).format('YYYY-MM-DD')
            }
          ]
        },
        {
          id: 'queueName',
          Header: 'Queue',
          accessor: 'group.queueName',
          width: 20
        },
        {
          id: 'initiator',
          Header: 'Initiator',
          accessor: 'initiator.email',
          width: 20
        },
        {
          id: 'createdAt',
          Header: 'Created At',
          accessor: (row) => moment(row.group.createdAt).format('YYYY-MM-DD'),
          width: 20
        },
        {
          id: 'toolbar',
          Header: 'Toolbar',
          accessor: (row) => {
            console.log('Row', row, selectedJob);
            if (selectedJob && selectedJob.group.uuid == row.group.uuid) {
              return (
                <Button onClick={() => setSelectedJob(null)}>
                  Close Details
                </Button>
              );
            } else {
              return (
                <Button onClick={() => setSelectedJob(row)}>
                  Show Details
                </Button>
              );
            }
          }
        }
      ];
  }, [selectedJob]);
  const now = moment().add(1, 'days').startOf('day');
  const [timeFrame, setTimeFrame] = useState({
    start: now.clone().subtract(7, 'days'),
    end: now
  });
  const [externalRefresh, setExternalRefresh] = useState(null);
  const [pagination, setPagination] = useState({ offset: 0, size: 10 });
  const [result, invokeGetCrawlbackList] = useGetCrawlbackJobList();
  const [updatedFilter, filter] = Filter({
    start: timeFrame.start,
    end: timeFrame.end
  });
  useEffect(() => setSelectedJob(null), [updatedFilter.status]);
  useEffect(() => {
    async function load() {
      if (!pagination) return;
      const result = await invokeGetCrawlbackList(
        pagination.offset,
        pagination.size,
        updatedFilter.start.format('YYYY-MM-DD'),
        updatedFilter.end.format('YYYY-MM-DD'),
        updatedFilter.status
      );
      //   console.log(result);
    }
    load();
  }, [
    JSON.stringify(pagination),
    JSON.stringify(updatedFilter),
    externalRefresh
  ]);
  useEffect(
    () => setPagination({ offset: 0, size: 10 }),
    [JSON.stringify(updatedFilter)]
  );
  const [table, checked] = Table({
    showCheckbox: false,
    data: result.data ? result.data.result : [],
    totalSize: result?.data?.total || 0,
    pageSize: pagination.size,
    pageIndex: pagination.offset / pagination.size,
    columns,
    fetchData: (state) => {
      console.log(state);
      setPagination({
        offset: state.pageIndex * state.pageSize,
        size: state.pageSize
      });
    }
  });
  return (
    <div className="p-6 bg-white">
      <div className="grid grid-cols-4">
        <div className="col-span-2">{filter}</div>
        <div className="col-span-2">
          <CrawlbackStatus from={updatedFilter.start} to={updatedFilter.end} />
        </div>
      </div>
      <div className="grid grid-cols-8">
        {!selectedJob && <div className={`col-span-8 p-1`}>
          {table}
        </div>}
        {selectedJob && (
          <div className="col-span-8 p-1">
            <div>
              <Button onClick={() => setSelectedJob(null)}>Close</Button>
            </div>
            <CrawlbackJobDetails
              key={selectedJob.group.uuid}
              jobId={selectedJob.group.uuid}
            />
          </div>
        )}
      </div>
      <CreateGroupJob
        onSuccess={(result) => {
          console.log('Success', result);
          setExternalRefresh(new Date().getTime());
        }}
        onError={(err) => console.log('Error', err)}
      />
    </div>
  );
};

export default CrawlbackPanel;
